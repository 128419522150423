.root {
  text-align: start;
  margin: 10em 2em 5em 5em;
  border: 0px solid #b12424;
  width: 40em;
}
.FormSubmitLabel {
  font-weight: 600;
  font-style: normal;
  font-size: 1.8em;
  color: white;
  text-transform: uppercase;
  text-decoration-line: none;
  text-decoration-style: solid;
  align-self: center;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.RightForm {
  padding: 5px;
  border: 0px solid black;
  width: 100%;
}
.RightFormTextArea {
}
.FormSubmitCol2 {
  display: flex;
  min-width: auto;
  margin: 4px;
  flex-direction: row;
  justify-items: start;
  overflow: auto;
  width: 96%;
  font-size: 1.8em;
  border-radius: 5px;
}
.FormSubmitMultiLine {
  min-height: 12em;
  display: flex;
  width: 100%;
}
.RightFormSubmit {
  border: 5px solid green;
  border-radius: 4px;
  display: flex;
  font-weight: 500;
  font-style: italic;
  color: white;
  background-color: green;
  &:hover {
    background-color: lightgreen;
    border: 0px solid green;
  }
  width: 100%;
  text-align: start;
  direction: ltr;
  justify-content: center;
}
.FormEmailNote {
  font-weight: 900;
  font-size: 1.2em;
  color: red;
  text-align: start;
  direction: ltr;
  text-shadow: 2.83px 2.83px 2px rgba(255, 255, 255, 0);
  font-style: normal;
  border: 0px solid red;
  display: flex;
  justify-items: center;
  justify-content: center;
}
.RightFormDiv {
  width: 100%;
  border: 0px solid #2ace2a;
  margin: 1px;
}
.FormButtonClick {
  font-size: 1.5em;
  width: 100%;
  color: green;
  border-radius: 40px;
  cursor: pointer;
  background-color: rgba(255, 165, 0, 0.79);
  text-decoration-style: solid;
  text-transform: capitalize;
  text-decoration-line: none;
  text-decoration-color: black;
  text-shadow: 1.41px 1.41px 9px rgba(255, 255, 255, 0.69);
}
.FormSubmitConfirm {
  font-size: 1.8em;
  margin: 4px;
  color: white;
}
