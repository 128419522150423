.root {
  min-width: 500px;
  padding: 10px;
  width: 50%;
  margin: 10px;
  overflow: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.TextBlock_title {
  margin-bottom: 1em;
  font-size: 4em;
  font-weight: 800;
  color: rgb(255, 255, 255);
  text-transform: uppercase;
  text-shadow: 4.95px 4.95px 1px black;
}
.TextBlock_items {
  align-self: stretch;
  padding: 10px;
}
.LeftDemoItem {
  font-weight: 600;
  padding: 10px;
  font-size: 2.3em;
  text-shadow: 3px 3px 2px black;
}
.rootdiv {
}
