.App {
  text-align: center;
  background-color: no-repeat;
  background-repeat: no-repeat, no-repeat;
  background-image: url(./library.jpg), none;
  background-position: 50%, 0%;
  background-size: cover, auto;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
  width: 100%;
  height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: no-repeat
    url('https://static.wixstatic.com/media/d9657b20f1b9473f90815b9da211bb2a.jpg/v1/fit/w_924,h_520/d9657b20f1b9473f90815b9da211bb2a.jpg')
    50% / cover;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  border: 2px solid black;
  background-repeat: no-repeat, no-repeat;
  background-image: url('https://static.wixstatic.com/media/d9657b20f1b9473f90815b9da211bb2a.jpg/v1/fit/w_924,h_520/d9657b20f1b9473f90815b9da211bb2a.jpg'),
    none;
  background-position: 50%, 0%;
  background-size: cover, auto;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.LeftDemo {
  width: 70%;
  color: #f8efef;
  text-align: start;
  margin: 0% 0px 0px;
  padding: 10px;
  align-self: start;
  position: static;
  top: 3em;
  left: 1em;
  display: inline;
  min-width: 300px;
}
.BottemDiv {
  margin: 1024px 0px 0px;
  color: white;
}
.MainRow {
  display: flex;
  grid-template-rows: 1fr;
  grid-template-columns: 2fr 1fr;
  overflow: auto;
  flex-direction: row;
  flex-wrap: wrap;
}
.RightFormDiv {
  display: flex;
  overflow: auto;
  right: 1em;
  margin: 0px;
  top: 3em;
  min-width: 200px;
  flex-wrap: wrap;
  flex-direction: row;
}
